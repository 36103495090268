.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: #11294c;
}
.footer p {
  color: #fff;
  padding: 15px 0;
  line-height: 20px;
  margin: 0 0 0px;

}
